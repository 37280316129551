import * as React from 'react';
import { MatchCards } from './match-cards/MatchCards';
import { connect } from 'react-redux';
import ViewSelection from './view-selection/ViewSelection';
import MatchFilter from '../match-filter/MatchFilter';
import SwipeableViews from 'react-swipeable-views';
import { setActiveFilter } from '../day-filter/reducer';
import { AppProps, AssociationRootState } from '..';
import { Dispatch, bindActionCreators } from 'redux';
import { MatchList } from './match-list/MatchList';
import { ViewSelectionState } from './view-selection/reducer';
import './MatchesOverview.scss';

const Swipeable: any = SwipeableViews

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setActiveFilter
}, dispatch)

interface OwnProps {
  appProps: AppProps
  matchSeriesUuid?: string
  favoritesRoute?: boolean
}

const mapStateToProps = (state: AssociationRootState, props: OwnProps) => {

  let matchDays = state.tickerState.matchDays
  if (props.matchSeriesUuid) {
    matchDays = matchDays.map(m => {
      return {
        ...m,
        matches: m.matches.filter(m => m.matchSeries === props.matchSeriesUuid)
      }
    })
  }

  if (props.favoritesRoute) {
    matchDays = matchDays.map(m => {
      return {
        ...m,
        matches: m.matches.filter(m => state.favorites.indexOf(m.id) > -1)
      }
    })
  }

  return {
    matchDays,
    matchSeries: state.tickerState.matchSeries,
    matchStatesByUuid: state.tickerState.matchStatesByUuid,
    selectedView: state.selectedView,
    settings: state.tickerState.settings,
    loading: state.tickerState.loading,
    error: state.tickerState.error,
    activeFilterDay: state.dayFilter.activeFilterDay,
    favorites: state.favorites
  }
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps

const MatchesOverview = (props: Props) => {

  const [isFixed, setIsFixed] = React.useState(false)
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const [threshold, setThreshold] = React.useState(0)

  const handleChangeIndex = (index: number) => {
    props.setActiveFilter(index)
  }

  React.useEffect(() => {
    setThreshold(ref.current!.clientHeight - 80)
  }, [ref, threshold])

  React.useEffect(() => {

    let scrollTop = document.documentElement.scrollTop

    const shrinkHeader = (e: any) => {
      scrollTop = e.target.documentElement.scrollTop
      if (scrollTop <= threshold) {
        setIsFixed(false)
      } else {
        setIsFixed(true)
      }
    }

    window.addEventListener('scroll', shrinkHeader);
    return () => window.removeEventListener('scroll', shrinkHeader)

  }, [isFixed, ref, window, threshold])

  const {
    loading,
    appProps,
    activeFilterDay,
    matchDays,
    matchStatesByUuid,
    settings,
    favorites,
    error
  } = props


  return <div className={'MatchesOverview ' + (isFixed ? 'header-fixed' : '')}>
    <div className="OverviewHeader" ref={ref}>
      <div className="App-title">{appProps.appTitle}</div>
      {!loading && <MatchFilter matchDays={matchDays} />}
    </div>
    <a href={appProps.adBannerUrl} className="AdBanner"></a>
    <ViewSelection />
    {error && <h1>{error}</h1>}
    {!loading
      && <div className="MatchDays">{
        <Swipeable
          style={{ "paddingBottom": "50px", "flex": "1 0 auto", "display": "flex", "flexDirection": "column" }}
          containerStyle={{ "flex": "1 0 auto", "display": "flex" }}
          animateHeight resistance index={activeFilterDay}
          onChangeIndex={handleChangeIndex}>
          {
            matchDays.map((matchDay, idx) => {
              return matchDay?.matches?.length === 0 && !loading
                ? <h1 key={idx} style={{ "padding": "50px" }}>An diesem Tag finden keine Spiele statt.</h1>
                : props.selectedView === ViewSelectionState.List
                  ? <MatchList key={idx}
                      matchDay={matchDay}
                      matchStatesByUuid={matchStatesByUuid}
                      matchSeries={props.matchSeries}
                      mode={settings.mode} />
                  : <MatchCards key={idx}
                      matchDay={matchDay}
                      matchStatesByUuid={matchStatesByUuid}
                      matchSeries={props.matchSeries}
                      mode={settings.mode}
                      favorites={favorites} />
            })
          }
        </Swipeable>
      }</div>
    }
  </div>

}

export default connect(mapStateToProps, mapDispatchToProps)(MatchesOverview)
